.container {
  max-width: 960px;
  padding-left: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
  
  
}

body {
  /* background-image: url(../src/img4.png.jpg); */
  background: linear-gradient(to bottom, #678990, #ebedf5);
}

.heading{
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
}

.input{
  text-align: center;
  margin-bottom: 2rem;
}

input {
  padding: 15px;
  border-radius: 7px;
  margin: 1rem;
  border: 2px solid black;
  background: transparent;
  outline: none;
  color: black;
}

::placeholder{
  color: black;
}

.btn{
  padding: 15px;
  font-size: 0.8rem;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: black;
  color: wheat;
  text-transform: uppercase;
  filter: drop-shadow(10px 10px 10px black);
}

.display-color{
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.rgbcolors{
  border-radius: 13px;
  padding: 1rem;
}

.rgbcolors {
  height: 110px;
  width: 230px;
  border-radius: 10px;
  padding: 0 10px; 
  cursor: cell;
}

.colors-bright {
  color: #fff;
}

.display-col {
  margin: 1.5rem;
  border-radius: 5px;
  padding: 1rem;
text-transform: uppercase;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

